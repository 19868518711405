.flex {
    display: flex;
}

.in-row {
    flex-direction: row;
}

.in-column {
    flex-direction: column;
}

.two-third {
    flex: 0.66;
}

.one-third {
    flex: 0.33;
}

.one-half {
    flex: 0.5;
}

.full-width {
    flex: 1;
}

.centered {

    align-items: center;
    text-align: center;
}