.image-selection-header {
    display: flex;
    padding: 0 0.2em 0.2em 0;

}

.image-selection-header label {
    padding: 0 0.5em 0 0;
}

.color-selection-section {
    display: flex;
    justify-content: space-around;
}

.image-selection-section {
    display: flex;
}

.image-selection-item {

    height: 300px;

    display: flex;
    flex-direction: column;
}

.image-selection-preview {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid lavender;
    min-height: 200px;
    align-items: center;
    position: relative;
}

.image-preview {
    max-height: 400px;
    max-width: 400px;
    width: auto;
    height: auto;
}

.inputFile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputFile + label {
    font-size: 1.25em;
    font-weight: 700;

    flex-grow: 1;
    text-align: center;
    align-self: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1em;
}

.inputFile:focus + label,
.inputFile + label:hover {
    cursor: pointer;
}

.edit-icon {
    position: absolute;
    right: 10px;
}
