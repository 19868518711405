.recipe-page-content {
    display: flex;
}

.recipe-page-description {
    flex: 1;
    padding: 1em;
}

.recipe-page-image {
    flex: 1;
    padding: 1em;
}

.small-screen-only h1 {
    display: none;
}

.recipe-image {
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 930px) {
    .recipe-page-content {
        display: flex;
        flex-direction: column-reverse;
    }

    .recipe-page-description > h1 {
        display: none;
    }

    .small-screen-only h1 {
        display: initial;
    }
}

@media only screen and (max-width: 737px) {
    #recipe-page {
        padding-right: 1em;
        padding-left: 1em;
    }

    .recipe-page-description {
        padding: 0;
    }

    .small-screen-only h1 {
        display: initial;
    }

    .small-screen-only {
        margin-bottom: 1em !important;
    }
}