#recipe-form {
    padding-bottom: 1em;
}

#recipe-form .row {

    margin-bottom: 1em;
}

#recipe-form h1 {

    text-align: center;
}

#recipe-form h5 {
    margin-bottom: 0rem;
}


.edit-action i {
    cursor: pointer;
}
.Form-step-container {
    width: 100%;
    max-width: 900px;
    margin: auto;
}

.Form-steps {
    display: flex;
    flex-direction: column;
}

.Form-step {
    margin-bottom: 1em;
}

.Form-step-action-button {
    display: flex;
    justify-content: space-between;
}
.Form-step-action-button > :first-child {
    margin-right: 1em;
}

.Form-step-action-button.align-end {
    justify-content: flex-end;
}

.error-block {
    display: flex;
    align-items: center;
    color: red;
    margin-right: 2em;
}

@media only screen and (max-width: 737px) {

    .Form-step-container {
        width: auto;
        padding-right: 1em;
        padding-left: 1em;
    }

    .Form-step-action-button {
        flex-direction: column;
    }

    .Form-step-action-button > div {

        margin-bottom: 1em;
        display: flex;
        justify-content: center;
    }
}

