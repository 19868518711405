.App {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App-name {

}

.App-content {
  flex: 3;
  color: black;
}

.App-footer {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em;
}

.Footer-app-name {
  display: flex;
  justify-content: center;
}

.Footer-app-hook {
  text-align: center;
}

.App-link {
  color: var(--link-color);
}

.App-header__left {
  display: flex;
  align-items: center;
}


@media only screen and (max-width: 737px) {

  .App-content {
    padding: 0;
    padding-top: 15px !important;
  }

  h1 {
    text-align: center;
  }
}
