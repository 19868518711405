:root {
    --header-max-height: 90px;
    --logo-max-height: 60px;
    --small-device-size: 737px
}

.App-header {
    background-color: var(--primary-color);
    color: var(--primary-text-color);
    height: var(--header-max-height);
    position: fixed;
    width: 100%;

    display: flex;
    flex-direction: row;
    font-size: calc(10px + 2vmin);
    z-index: 100;

    padding-top: 0.2em;
    padding-bottom: 0.2em;

    flex: 1;
}

.Search-bar {
    z-index: 101;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: var(--header-max-height);
    position: fixed;
    padding: 0.3em 1em;
}

.Search-bar div:first-child {
    flex: 10;
}

.Search-bar div:last-child {
    flex: 1;
}

.Search-bar svg {
    height: 45px;
    width: 45px;
}

.clickable {
    display: flex;
}

.clickable svg {
    height: 45px !important;
    width: 45px !important;
}

.input-container {
    width: 100%;
    height: 100%;
    z-index: 101;
}

.input-container input {
    width: 100%;
    height: 100%;
    padding: 0 72px 0 40px;
    font-size: 1.714rem;
    box-sizing: border-box;
    border: none;
    font-weight: 300;
    outline: none;
}

.App-header__right {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    flex: 3;
}

.App-brand {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 1em;
    padding-left: 1em;
    flex: 3;
    font-size: x-large;
}

.App-logo {
    height: var(--logo-max-height);
    pointer-events: none;
}

nav ul {
    display: flex;
    flex-direction: row;
    margin-top: 0;
    padding-left: 0
}

.fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background-color: #290302;
    z-index: 10;
}

.sidenav.fixed {

    display: flex;
    align-items: center;
}

nav ul {
    display: flex;
    justify-content: space-around;
    margin-bottom: 0;

}

nav ul li {
    list-style-type: none;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    margin-right: 2em;
}

nav a {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration-line: none;

    color: var(--link-color);
    font-size: large;
}
nav a span {
    margin-left: 0.5em;
}

nav a:hover {
    color: var(--link-pressed-color);
}

@media screen and (max-width: 1045px) {
    nav.fixed ul li a {
        padding: 1em;
        font-size: 16px;
    }
}

@media screen and (max-width: 700px) {
    nav a {
        font-size: 16px;
    }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 610px) {
    nav a {
        padding: 1em;
        font-size: 16px;
    }
}

@media screen and (max-width: 574px) {
    nav a {
        padding: 1em;
        font-size: 14px;
    }
}

@media only screen and (max-width: 431px) {

    .nav-text {
        font-size: 2.2rem !important;
    }
}

@media only screen and (max-width: 737px) {

    .App-header {
        position: fixed;
        width: 100%;
        z-index: 100;
        flex-direction: row;
        justify-content: flex-start;
        height: 55px;
        background-color: var(--primary-color) !important;
        background-image: none !important;
        font-size: calc(12px + 2vmin);

    }

    .Search-bar {
        height: 55px;
    }

    .Search-bar div:first-child {
        flex: 5;
    }

    .Search-bar svg {
        height: 35px;
        width: 35px;
    }


    .App-header__right {
        flex: 2;
    }

    .clickable svg {
        height: 35px !important;
        width: 35px !important;
    }

    .App-content {
        margin-top: 55px !important;
    }

    .App-menu {
        display: none;
        font-size: calc(12px + 2vmin);

    }

    .App-menu-icon {
        min-width: 64px;
        display: flex;
        justify-content: center;
    }

    .App-content {
        right: 0;
        left: 0;
        bottom: 0;
    }

    #App-menu-icon {
        cursor: pointer;
        width: 48px;
        height: 55px;
    }

    #App-menu-icon:after {
        content: '';
        position: absolute;
        display: block;
        width: 18px;
        height: 0px;
        top: 17px;
        left: 18px;
        box-shadow: 0 10px 0 1px var(--secondary-color), 0 16px 0 1px var(--secondary-color), 0 22px 0 1px var(--secondary-color);
    }

    .App-logo {
        display: none;
    }

    .App-name {
        width: 100%;
        padding-bottom: 0;
    }

    .App-nav {
        height: 100%; /* 100% Full-height */
        width: 0; /* 0 width - change this with JavaScript */
        position: fixed; /* Stay in place */
        z-index: 1; /* Stay on top */
        top: 0; /* Stay at the top */
        left: 0;
        background-color: var(--primary-color);
        overflow-x: hidden; /* Disable horizontal scroll */
        transition: 0.2s; /* 0.5 second transition effect to slide in the sidenav */
    }

    .App-nav.fixed {
        display: block;
    }

    .with--sidebar .App-nav {
        width: 250px;
    }

    .App-nav ul {

        display: flex;
        flex-direction: column;
        text-decoration: none;
    }

    .with--sidebar .site-cache {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right:  0;

        z-index: 10;
        background-color: rgba(0,0,0,0.6);
    }

    body.with--sidebar {
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .App-brand a {
        display: flex;
        align-items: center;
        padding: 0.5em;
    }
    .App-brand {
        flex: 6;
        padding-left: 0;
    }

}
