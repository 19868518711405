:root {
  --primary-color: #80DEEA;
  --primary-text-color: #f64487;

  --secondary-color: #f64487;
  --secondary-text-color: #FFECECE6;

  --link-color: #f64487;
  --link-pressed-color: #FFECECE6;

  --white: #FFECECE6;

  --background-color: #F5F5F6
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--background-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page {
  display: flex;
  height: 100%;
  padding-bottom: 2em;
}

.page.dense {
  padding: 0;
  margin: 0;
}

.page-content {
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 1em;
  padding-bottom: 1em;
}

.page-content.dense {
  padding: 0;
}

.page-content.no-padding {
  padding: 0;
}
