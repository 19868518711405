#recipe-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 1em;
    padding-right: 1em;
}

.recipe-list-content {
    display: flex;
    flex-direction: column;
}

.recipe-list {
    flex: 1.6;
    display: flex;
    list-style-type: none;
    flex-wrap: wrap;
    justify-content: space-around;
}


.recipe-list li  {
    position: relative;
    width: 400px;
    margin: 0.2em 0.2em 2em;
    display: flex;
    flex-direction: column;
}

.recipe-cartouche {
    position: absolute;
    background: rgba(0, 0, 0, 0.5) none repeat scroll 0% 0%;
    opacity: 0.9;
    bottom: 0;
    width: 100%;
    height: 68px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.recipe-cartouche:nth-child(1) {
    font-size: larger;
}

.recipe-cartouche:nth-child(2) {
    font-size: medium;
}

.recipe-list li img {
    width: 400px;
    height: auto;
}

.recipe-list li a {
    display: flex;
}

@media only screen and (max-width: 737px) {

    #recipe-list {
        padding: 0;
    }

    .recipe-list {
        justify-content: center;
    }

    .recipe-list li {
        margin: 0;
        padding: 0;
        width: auto;
    }

    .recipe-list li img {
        display: block;
    }

}