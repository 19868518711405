.page-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.8em 0 1em 1em;

  .left {
    display: none;
  }

  .page-header-title {
    margin-left: 0.5em; flex: 0.4
  }

  .right {
    flex: 0;
  }
}

.header {
  padding: 1.8em 0 1em 1em ;

  .subtitle {
    color: #4a4949;
    padding-left: 2.2em;
  }
}

@media only screen and (max-width: 1000px) {

  .page-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5em;
    justify-content: center;

    .left {
      display: block;
      align-self: flex-start;
      padding: 0;
    }

    .page-header-title {
      display: block;
      width: 100%;
      text-align: center;
      flex: 1;
      margin: 0;
    }

    .right {
      flex: 0;
    }
  }

  .header {
    padding: 0.5em;
  }
}
