.block {

  background-color: white;
  margin: 0 32px 32px 32px;
  padding: 0 1em;
  box-sizing: border-box;


  .block-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .block-header.empty {
    margin-bottom: 1em;
  }

  .footer-actions {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }


}

.block.no-padded {
  padding: 0;
  .block-header {
    padding: 1em;
  }
}

.block.no-margin {
  margin: 0;
}

.block.no-border {
  border: none;
}

.block.no-shadow {
  box-shadow: none;
}

.block.fullwidth {
  padding: 0;

  .block-header {
    padding: 1em;
  }
}

@media only screen and (max-width: 1000px) {

  .block {
    margin: 0 0 16px 0;
  }

}
